/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";
import _toString from "lodash/toString";

import RemarkBox from "./remark_box";

import ModalView from "../../components/ModalView";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckbox from "../../components/FormCheckbox";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
} from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { getAPIErrorMessage } from "../../helpers/action";
import {
  checkCourseAccess,
  formatStudentCourseAccessFormData,
  getDuplicateAccessMessage,
} from "../../helpers/course_access";

import { InverseButton, GreyButton } from "../../styles/button";
import { WarningBox, ErrorBox } from "../../styles/message";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  border: 2px solid #212121;
`;

class MassEditCourseAccess extends React.Component {
  utcOffSet = 8;

  state = {
    modalData: false,
    loading: false,
    duplicateAccess: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { trainees, column, columns, email_type, email_templates, open } =
      this.props;
    if (open && !prevProps.open) {
      this.setState({
        modalData: this.getInitialModalData(),
        loading: false,
        duplicateAccess: [],
      });
    } // end - open
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  handleUpdate = () => {
    const { schedule, authData, onUpdate } = this.props;
    const { modalData } = this.state;
    let error = false,
      eligibleStudents = this.getEligibleStudents();

    if (!(eligibleStudents && isArrayExists(eligibleStudents)))
      error = "No eligible student(s) found for the selected action.";

    if (
      modalData &&
      modalData.action &&
      modalData.action === "set_deactivate" &&
      !modalData.deactivated
    )
      error = "Please select an inactive date";

    if (!(modalData && modalData.action && !_isEmpty(modalData.action)))
      error = "Please select an action";

    if (
      !(
        modalData &&
        modalData.lms_course_id &&
        !_isEmpty(modalData.lms_course_id)
      )
    )
      error = "Please select a course";

    if (error) {
      triggerErrorAlert(error);
    } else {
      if (modalData.id === "3" || modalData.status === "inactivate") {
        if (onUpdate) {
          onUpdate(this.updateTrainees(eligibleStudents));
          this.handleClose();
        }
      } else {
        this.setState({ loading: true, duplicateAccess: [] });

        checkCourseAccess({
          trainees: formatStudentCourseAccessFormData(eligibleStudents),
          lms_course_id: modalData.lms_course_id,
          schedule_id: schedule.id,
        })
          .then((duplicateAccess) => {
            console.log("duplicateAccess", duplicateAccess);
            if (duplicateAccess && isArrayExists(duplicateAccess)) {
              this.setState({ loading: false, duplicateAccess });
            } else {
              this.setState({ loading: false, duplicateAccess: [] });
              setTimeout(() => {
                if (onUpdate) {
                  onUpdate(this.updateTrainees(eligibleStudents));
                  this.handleClose();
                } // end - onUpdate
              }, 200);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            triggerErrorAlert(getAPIErrorMessage(err));
          });
      }
    }
  };

  handleFormUpdate = (newValue, key, subkey, subVal) => {
    const { modalData } = this.state;
    let newData = modalData ? cloneCollections(modalData) : {};
    switch (key) {
      case "action":
        newData[key] = newValue;
        break;
      default:
        if (
          subkey &&
          (subkey === "checked" || subkey === "unchecked") &&
          subVal &&
          !_isEmpty(subVal)
        ) {
          // use subVal as the new value
          newData[key] = subVal;
        } else {
          // otherwise - just update normally
          newData[key] = newValue;
        }
        break;
    } // end - key

    this.setState({ modalData: newData });
  };

  updateTrainees = (students) => {
    const { trainees, courses, schedule } = this.props;
    const { modalData } = this.state;
    let newTrainees =
      trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [];
    _forEach(students, (student) => {
      let index =
        trainees && isArrayExists(trainees)
          ? _findIndex(trainees, { id: student.id })
          : -1;
      if (index >= 0) {
        let courseProfile =
            courses && isArrayExists(courses)
              ? _find(courses, { lms_course_id: modalData.lms_course_id })
              : false,
          productProfile =
            schedule &&
            schedule.course_access &&
            isArrayExists(schedule.course_access)
              ? _find(schedule.course_access, {
                  lms_course_id: modalData.lms_course_id,
                })
              : false,
          course_index =
            trainees[index] &&
            trainees[index].course_access &&
            isArrayExists(trainees[index].course_access)
              ? _findIndex(
                  trainees[index].course_access,
                  (c) => _toString(c.id) === _toString(modalData.lms_course_id)
                )
              : -1;
        switch (modalData.action) {
          case "activate":
            if (course_index >= 0) {
              newTrainees[index].course_access[course_index].status = "active";
              newTrainees[index].course_access[course_index].activated =
                moment().utcOffset(this.utcOffSet).startOf("date").valueOf();
              newTrainees[index].course_access[course_index].concluded = 0;
              newTrainees[index].course_access[course_index].active_remark =
                modalData.active_remark ? modalData.active_remark : "";
              // reset deactivated if no set_deactivation
              if (
                newTrainees[index].course_access[course_index]
                  .set_deactivation &&
                newTrainees[index].course_access[course_index]
                  .set_deactivation === "no"
              ) {
                newTrainees[index].course_access[course_index].deactivated = 0;
              }
            } else {
              newTrainees[index].course_access.push({
                id: modalData.lms_course_id,
                course_id:
                  productProfile && productProfile.id
                    ? productProfile.id
                    : courseProfile && courseProfile.id
                    ? courseProfile.id
                    : "", // course profile ID
                course_code:
                  productProfile && productProfile.course_code
                    ? productProfile.course_code
                    : courseProfile && courseProfile.course_code
                    ? courseProfile.course_code
                    : "", // from course profile
                status: "active", // course access status
                activated: moment()
                  .utcOffset(this.utcOffSet)
                  .startOf("date")
                  .valueOf(), // timestamp of activation
                set_deactivation: "no",
                deactivated: 0, // timestamp of deactivation
                concluded: 0, // timestamp of concluded
                active_remark: modalData.active_remark
                  ? modalData.active_remark
                  : "",
              });
            } // end - course_index
            break;
          case "deactivate":
            if (course_index >= 0) {
              newTrainees[index].course_access[course_index].status =
                "inactivate";
              newTrainees[index].course_access[course_index].set_deactivation =
                "no";
              newTrainees[index].course_access[course_index].deactivated =
                moment().utcOffset(this.utcOffSet).endOf("date").valueOf();
              newTrainees[index].course_access[course_index].inactivate_remark =
                modalData.inactivate_remark ? modalData.inactivate_remark : "";
            }
            break;
          case "concluded":
            if (course_index >= 0) {
              newTrainees[index].course_access[course_index].status =
                "conclude";
              newTrainees[index].course_access[course_index].set_deactivation =
                "no";
              newTrainees[index].course_access[course_index].deactivated = 0;
              newTrainees[index].course_access[course_index].concluded =
                moment().utcOffset(this.utcOffSet).endOf("date").valueOf();
              newTrainees[index].course_access[course_index].conclude_remark =
                modalData.conclude_remark ? modalData.conclude_remark : "";
            }
            break;
          case "set_deactivate":
            if (course_index >= 0) {
              newTrainees[index].course_access[course_index].set_deactivation =
                "yes";
              newTrainees[index].course_access[course_index].deactivated =
                modalData.deactivated
                  ? moment(modalData.deactivated)
                      .utcOffset(this.utcOffSet)
                      .endOf("date")
                      .valueOf()
                  : 0;
            }
            break;
        }
      }
    });
    return newTrainees;
  };

  verifyIfStudentIsEligible = (trainee) => {
    const { modalData } = this.state;
    let valid = false,
      current_access =
        trainee.course_access && isArrayExists(trainee.course_access)
          ? _find(
              trainee.course_access,
              (c) => _toString(c.id) === _toString(modalData.lms_course_id)
            )
          : false;
    switch (modalData.action) {
      case "activate":
        if (
          !(
            current_access &&
            current_access.status &&
            (current_access.status === "active" ||
              current_access.status === "conclude")
          )
        ) {
          valid = true;
        }
        break;
      case "deactivate":
      case "set_deactivate":
        if (
          current_access &&
          !(current_access.status && current_access.status === "inactivate")
        ) {
          valid = true;
        }
        break;
      case "concluded":
        if (
          current_access &&
          !(current_access.status && current_access.status === "conclude")
        ) {
          valid = true;
        }
        break;
    }
    return valid;
  };

  getIneligibleReason = (trainee) => {
    const { modalData } = this.state;
    let reason = "",
      current_access =
        trainee.course_access &&
        isArrayExists(trainee.course_access) &&
        modalData &&
        modalData.lms_course_id &&
        !_isEmpty(modalData.lms_course_id)
          ? _find(
              trainee.course_access,
              (c) => _toString(c.id) === _toString(modalData.lms_course_id)
            )
          : false;
    if (modalData && modalData.action && !_isEmpty(modalData.action)) {
      switch (modalData.action) {
        case "activate":
          if (
            current_access &&
            current_access.status &&
            current_access.status === "active"
          ) {
            reason =
              "the selected course has already been activated for this student.";
          } else if (
            current_access &&
            current_access.status &&
            current_access.status === "conclude"
          ) {
            reason =
              "the selected course has already been concluded for this student.";
          }
          break;
        case "deactivate":
        case "set_deactivate":
          if (current_access) {
            if (
              current_access.status &&
              current_access.status === "inactivate"
            ) {
              reason =
                "the selected course has already been deactivated for this student.";
            }
          } else {
            reason =
              "the selected course wasn't activated for this student yet.";
          }
          break;
        case "concluded":
          if (current_access) {
            if (current_access.status && current_access.status === "conclude") {
              reason =
                "the selected course has already been concluded for this student.";
            }
          } else {
            reason =
              "the selected course wasn't activated for this student yet.";
          }
          break;
      }
    } // end - modalData.action
    return " Reason: " + reason;
  };

  getEligibleStudents = () => {
    const { modalData } = this.state;
    const students = this.getSelectedTrainees();
    return students &&
      isArrayExists(students) &&
      modalData &&
      modalData.lms_course_id &&
      !_isEmpty(modalData.lms_course_id) &&
      modalData.action &&
      !_isEmpty(modalData.action)
      ? _filter(students, this.verifyIfStudentIsEligible)
      : [];
  };

  getIneligibleStudents = () => {
    const { modalData } = this.state;
    const students = this.getSelectedTrainees();
    return students &&
      isArrayExists(students) &&
      modalData &&
      modalData.lms_course_id &&
      !_isEmpty(modalData.lms_course_id) &&
      modalData.action &&
      !_isEmpty(modalData.action)
      ? _filter(students, (t) => {
          return !this.verifyIfStudentIsEligible(t) ? true : false;
        })
      : [];
  };

  getSelectedTrainees = () => {
    const { trainees, selected } = this.props;
    return trainees && isArrayExists(trainees)
      ? _filter(trainees, (t) => {
          return t && t.id && _find(selected, { id: t.id }) ? true : false;
        })
      : [];
  };

  getCourseOptions = () => {
    const { schedule, courses, lms_courses } = this.props;
    let options = [];
    if (
      schedule &&
      schedule.course_access &&
      isArrayExists(schedule.course_access)
    ) {
      _forEach(schedule.course_access, (course) => {
        let selected =
            courses && isArrayExists(courses)
              ? _find(courses, { id: course.id })
              : false,
          name =
            course.name && !_isEmpty(course.name)
              ? course.name
              : (selected && selected.name) || "",
          code =
            course.course_code && !_isEmpty(course.course_code)
              ? course.course_code
              : (selected && selected.course_code) || "";
        if (course && course.lms_course_id) {
          options.push({
            value: _toString(course.lms_course_id),
            label:
              (code && !_isEmpty(code) ? "[" + code + "] " : "") + (name || ""),
          });
        } // end - selected
      });
    } // end - schedule.course_access
    return [
      { value: "", label: "Select a Course" },
      ..._sortBy(options, [(o) => o.label.toLowerCase()]),
    ];
  };

  getInitialModalData = () => {
    return {
      lms_course_id: "",
      action: "",
      deactivated: 0,
    };
  };

  convertToStatus = (action) => {
    let status = "";
    switch (action) {
      case "activate":
        status = "active";
        break;
      case "deactivate":
      case "set_deactivate":
        status = "inactivate";
        break;
      case "concluded":
        status = "conclude";
        break;
    }
    return status;
  };

  renderModalForm = () => {
    const { courses, lms_courses, trainees } = this.props;
    const { modalData, loading, duplicateAccess } = this.state;
    const ineligibleStudents = this.getIneligibleStudents();
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormSelect
              label="Course"
              name="lms_course_id"
              value={(modalData && modalData.lms_course_id) || ""}
              options={this.getCourseOptions()}
              disabled={loading}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
          </Grid>
          <Grid item xs={12}>
            <FormRadio
              label="Action"
              name="action"
              value={(modalData && modalData.action) || ""}
              options={[
                // { value: '', label: 'Select an action to perform' },
                { value: "activate", label: "Activate the Selected Course" },
                {
                  value: "deactivate",
                  label: "De-activate the Selected Course",
                },
                { value: "concluded", label: "Conclude the Selected Course" },
                {
                  value: "set_deactivate",
                  label: "Set inactive date for the Selected Course",
                },
              ]}
              disabled={loading}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          {modalData &&
          modalData.action &&
          modalData.action === "set_deactivate" ? (
            <FormDatePicker
              label="Date of Inactive starting..."
              name="deactivated"
              noDefaultVal={true}
              value={
                modalData && modalData.deactivated
                  ? modalData.deactivated
                  : null
              }
              disabled={loading}
              onChange={this.handleFormUpdate}
            />
          ) : null}

          <RemarkBox
            disabled={loading}
            modalData={{
              ...modalData,
              status: this.convertToStatus(modalData.action),
            }}
            handleFormUpdate={this.handleFormUpdate}
          />

          {ineligibleStudents && isArrayExists(ineligibleStudents) ? (
            <Grid item xs={12}>
              <WarningBox>
                <i className="fa fa-exclamation-triangle"></i>
                <strong style={{ fontWeight: "700" }}>
                  The following student(s) are not eligible for the action
                  selected:
                </strong>
                <br />
                <ul style={{ listStyle: "circle", paddingLeft: "15px" }}>
                  {ineligibleStudents.map((student) => (
                    <li key={student.id} style={{ paddingBottom: "5px" }}>
                      {(student.name || "") +
                        " (" +
                        (student.email || "") +
                        ")"}
                      <br />
                      {this.getIneligibleReason(student)}
                    </li>
                  ))}
                </ul>
              </WarningBox>
            </Grid>
          ) : null}

          {duplicateAccess && isArrayExists(duplicateAccess) ? (
            <Grid item xs={12}>
              <ErrorBox>
                <i className="fa fa-exclamation-triangle"></i>
                <div>
                  {duplicateAccess.map((student) => (
                    <div key={student.id} style={{ marginBottom: "5px" }}>
                      {getDuplicateAccessMessage(student, this.props.schedules)}
                    </div>
                  ))}
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  render() {
    const { open, schedule } = this.props;
    const { loading } = this.state;
    return (
      <>
        <ModalView
          open={open}
          title={"Mass Edit Course Access"}
          onClose={this.handleClose}
          disableBackdrop={true}
          actionLabel="Update"
          maxWidth="sm"
          processing={loading}
          doAction={this.handleUpdate}
          contents={this.renderModalForm()}
        />
      </>
    );
  }
}

export default compose(connect(), withRouter)(MassEditCourseAccess);
