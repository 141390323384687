import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

import { callFunctionsAPI } from "./action";
import { isArrayExists } from "./validation";
export const checkCourseAccess = async ({
  trainees,
  lms_course_id,
  schedule_id,
}) => {
  const response = await callFunctionsAPI({
    method: "post",
    url: "class",
    action: "check_course_access",
    formData: { trainees, lms_course_id, schedule_id },
  });
  return response;
};

export const formatStudentCourseAccessFormData = (list = []) => {
  let trainees = [];

  if (list && isArrayExists(list)) {
    trainees = list.map((item) => {
      let data = {
        id: item.id,
        email: item.email,
        // course_access:
        //   item.course_access && isArrayExists(item.course_access)
        //     ? item.course_access
        //     : [],
        // schedule_id: item.schedule_id,
      };
      return data;
    });
  }
  return trainees;
};

export const getDuplicateAccessMessage = (trainee, schedules) => {
  const schedule = _find(schedules, { id: trainee.schedule_id });
  let class_name = "";
  if (schedule) {
    class_name = schedule.class_name || "";
  }
  return `This student (${
    trainee.email
  }) already have access to this course in another class ${
    !_isEmpty(class_name) ? `(${class_name})` : ""
  }`;
};
