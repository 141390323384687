/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _toLower from "lodash/toLower";

import RemarkBox from "./remark_box";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckbox from "../../components/FormCheckbox";
import ToolTipInfo from "../../components/ToolTipInfo";

import { ErrorBox } from "../../styles/message";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import {
  checkCourseAccess,
  formatStudentCourseAccessFormData,
  getDuplicateAccessMessage,
} from "../../helpers/course_access";
import { triggerErrorAlert } from "../../helpers/alert";
import { getAPIErrorMessage } from "../../helpers/action";

import { ENROLLMENT_STATUS_OPTIONS } from "../../constants";

const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  border: 2px solid #212121;
`;

class CourseAccessModal extends React.Component {
  utcOffSet = 8;

  state = {
    modalData: false,
    loading: false,
    duplicateAccess: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { courseData, traineeData, courseID, open } = this.props;
    if (open && !prevProps.open) {
      this.setState({
        modalData: this.getInitialModalData(courseData, traineeData, courseID),
      });
    } // end - open
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  handleUpdate = () => {
    const { courseData, courseID, traineeData, onUpdate } = this.props;
    const { modalData } = this.state;
    let newData = traineeData ? cloneCollections(traineeData) : {},
      courseAccessData =
        traineeData &&
        traineeData.course_access &&
        isArrayExists(traineeData.course_access)
          ? cloneCollections(traineeData.course_access)
          : [],
      index = _findIndex(courseAccessData, { id: modalData.id });

    if (index >= 0) {
      courseAccessData[index] = cloneCollections(modalData);
    } else {
      courseAccessData.push(modalData);
    }

    newData.course_access = cloneCollections(courseAccessData);

    if (modalData.id === "3" || modalData.status === "inactivate") {
      // if course id is 3, then no need to check for duplicate access
      if (onUpdate) {
        onUpdate(newData);
        this.handleClose();
      }
    } else {
      this.setState({ loading: true });

      checkCourseAccess({
        trainees: formatStudentCourseAccessFormData([newData]),
        lms_course_id: modalData.id,
        schedule_id: newData.schedule_id,
      })
        .then((duplicateAccess) => {
          if (duplicateAccess && isArrayExists(duplicateAccess)) {
            this.setState({ loading: false });
            triggerErrorAlert(
              getDuplicateAccessMessage(
                duplicateAccess[0],
                this.props.schedules
              ),
              5000
            );
          } else {
            this.setState({ loading: false });
            setTimeout(() => {
              if (onUpdate) {
                onUpdate(newData);
                this.handleClose();
              } // end - onUpdate
            }, 200);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          triggerErrorAlert(getAPIErrorMessage(err));
        });
    }
  };

  handleFormUpdate = (newValue, key, subkey, subVal) => {
    const { courseData, courseID, traineeData, onUpdate } = this.props;
    const { modalData } = this.state;
    let newData = modalData ? cloneCollections(modalData) : {};
    switch (key) {
      case "set_deactivation":
        if (
          subkey &&
          (subkey === "checked" || subkey === "unchecked") &&
          subVal &&
          !_isEmpty(subVal)
        ) {
          newData[key] = subVal;
          if (subVal && subVal === "yes") {
            newData["deactivated"] = moment()
              .utcOffset(this.utcOffSet)
              .endOf("date")
              .valueOf();
          } else {
            // if status wasn't inactive - then reset deactivated date
            if (!(modalData.status && modalData.status === "inactive"))
              newData["deactivated"] = 0;
          } // end - subVal
        } // end - subkey
        break;
      case "status":
        newData[key] = newValue;
        if (newValue === "active") {
          newData["activated"] = moment()
            .utcOffset(this.utcOffSet)
            .startOf("date")
            .valueOf();
          newData["concluded"] = 0;
          // reset deactivated if no set_deactivation
          if (modalData.set_deactivation && modalData.set_deactivation === "no")
            newData["deactivated"] = 0;
        } else if (newValue === "inactivate") {
          newData["deactivated"] = moment()
            .utcOffset(this.utcOffSet)
            .endOf("date")
            .valueOf();
          // reset set_deactivation if deactivated now
          newData["set_deactivation"] = "no";
        } else if (newValue === "conclude") {
          // reset set_deactivation if conclude now
          newData["set_deactivation"] = "no";
          newData["deactivated"] = 0;
          newData["concluded"] = moment()
            .utcOffset(this.utcOffSet)
            .endOf("date")
            .valueOf();
        }
        break;
      default:
        if (
          subkey &&
          (subkey === "checked" || subkey === "unchecked") &&
          subVal &&
          !_isEmpty(subVal)
        ) {
          // use subVal as the new value
          newData[key] = subVal;
        } else {
          // otherwise - just update normally
          newData[key] = newValue;
        }
        break;
    } // end - key

    this.setState({ modalData: newData });
  };

  getCourseProfile = (courseID) => {
    const { schedule, courses } = this.props;
    let courseProfile =
        courses && isArrayExists(courses)
          ? _find(courses, { lms_course_id: courseID })
          : false,
      productProfile =
        schedule &&
        schedule.course_access &&
        isArrayExists(schedule.course_access)
          ? _find(schedule.course_access, { lms_course_id: courseID })
          : false;
    return productProfile ? productProfile : courseProfile || false;
  };

  getInitialModalData = (courseData, traineeData, courseID) => {
    const courseProfile = this.getCourseProfile(courseID);
    return courseData && isObjectExists(courseData)
      ? cloneCollections(courseData)
      : {
          id: courseID,
          course_id: courseProfile && courseProfile.id ? courseProfile.id : "", // course profile ID
          course_code:
            courseProfile && courseProfile.course_code
              ? courseProfile.course_code
              : "", // from course profile
          status: "", // course access status
          activated: 0, // timestamp of activation
          set_deactivation: "no",
          deactivated: 0, // timestamp of deactivation
          concluded: 0, // timestamp of concluded
        };
  };

  getEnrollmentStatusOptions = () => {
    let options = [],
      item = false,
      currentStatus = this.getCurrentCourseEnrollmentStatus();
    _forEach(ENROLLMENT_STATUS_OPTIONS, (option) => {
      if (
        option.value !== "registered" &&
        option.value !== "confirmed" &&
        option.value !== "delete"
      ) {
        switch (option.value) {
          case "active":
            // if not conclude
            if (!(currentStatus && currentStatus === "conclude")) {
              item = cloneCollections(option);
              item.tooltip = (
                <>
                  Active enrolments have full participation in the course. They
                  can view the course and participate in all course activities,
                  which means they can submit assignments and reply to
                  discussions. They can also view course grades. Read more{" "}
                  <a
                    href="https://s3.amazonaws.com/tr-learncanvas/docs/CanvasEnrollmentStatusComparison.pdf"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </>
              );
              options.push(item);
            }
            break;
          case "inactivate":
            if (currentStatus) {
              item = cloneCollections(option);
              item.tooltip = (
                <>
                  Inactive enrolments are students previously enrolled in a
                  course but who can no longer access course content. This
                  status can be used for students who do not pay fee or drop the
                  course at a future date. Read more{" "}
                  <a
                    href="https://s3.amazonaws.com/tr-learncanvas/docs/CanvasEnrollmentStatusComparison.pdf"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </>
              );
              options.push(item);
            }
            break;
          case "conclude":
            if (currentStatus) {
              item = cloneCollections(option);
              item.tooltip = (
                <>
                  Concluded enrolments are students who can only view the course
                  in a read-only format. This is the same status that is
                  automatically given to a student when a course is completed by
                  him/her. Read more{" "}
                  <a
                    href="https://s3.amazonaws.com/tr-learncanvas/docs/CanvasEnrollmentStatusComparison.pdf"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </>
              );
              options.push(item);
            }
            break;
        }
      } // end - option.value
    });
    return options;
  };

  getCurrentCourseEnrollmentStatus = () => {
    const { currentTrainees, traineeData, courseID } = this.props;
    const { modalData } = this.state;
    let currentTrainee =
        traineeData &&
        traineeData.id &&
        !_isEmpty(traineeData.id) &&
        currentTrainees &&
        isArrayExists(currentTrainees)
          ? _find(currentTrainees, { id: traineeData.id })
          : false,
      currentCourseAccess =
        modalData &&
        currentTrainee &&
        currentTrainee.course_access &&
        isArrayExists(currentTrainee.course_access)
          ? _find(
              currentTrainee.course_access,
              (c) => c.id.toString() === modalData.id.toString()
            )
          : false;
    return currentCourseAccess &&
      currentCourseAccess.status &&
      !_isEmpty(currentCourseAccess.status)
      ? currentCourseAccess.status
      : false;
  };

  isNewEnrollment = () => {
    return this.getCurrentCourseEnrollmentStatus() ? false : true;
  };

  renderForm = () => {
    const { modalData, loading } = this.state;
    return (
      <div>
        <div style={{ padding: "0 8px" }}>
          <FormRadio
            label="Enrolment Status"
            name="status"
            inline={true}
            value={modalData && modalData.status ? modalData.status : ""}
            options={this.getEnrollmentStatusOptions()}
            disabled={loading}
            onChange={this.handleFormUpdate}
          />
        </div>
        {/* <FormDatePicker label="Activate Date" name="activated" noDefaultVal={true} value={( modalData && modalData.activated ? modalData.activated : null )} onChange={this.handleFormUpdate} /> */}
        {this.isNewEnrollment() ? null : (
          <div style={{ padding: "8px 8px 0 8px" }}>
            <FormCheckbox
              label={
                <>
                  <span style={{ paddingRight: "5px" }}>
                    {"Set Inactive Date"}
                  </span>
                  <ToolTipInfo content="Set date of Inactive ahead of time. So that the student can still access LMS until Inactive Date." />
                </>
              }
              name="set_deactivation"
              disabled={
                loading ||
                (modalData.status &&
                  (modalData.status === "inactivate" ||
                    modalData.status === "conclude"))
                  ? true
                  : false
              }
              value={
                modalData && modalData.set_deactivation
                  ? modalData.set_deactivation
                  : "no"
              }
              checked="yes"
              unchecked="no"
              onChange={this.handleFormUpdate}
            />
          </div>
        )}
        <RemarkBox
          modalData={modalData}
          loading={loading}
          handleFormUpdate={this.handleFormUpdate}
        />
      </div>
    );
  };

  render() {
    const { open, courseID } = this.props;
    const { loading } = this.state;
    const courseProfile = this.getCourseProfile(courseID);
    return (
      <ModalView
        open={open}
        title={
          "Edit Course Access" +
          (courseProfile &&
          courseProfile.course_code &&
          !_isEmpty(courseProfile.course_code)
            ? " For " + courseProfile.course_code
            : "")
        }
        onClose={this.handleClose}
        disableBackdrop={true}
        actionLabel="Update"
        processing={loading}
        doAction={this.handleUpdate}
        contents={this.renderForm()}
      />
    );
  }
}

export default CourseAccessModal;
