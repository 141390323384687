/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _remove from "lodash/remove";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _slice from "lodash/slice";
import _reverse from "lodash/reverse";
import _toLower from "lodash/toLower";

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import FormSelect2 from "../../components/FormSelect2";

import { InfoButton, ErrorButton } from "../../styles/button";
import { WrapWord } from "../../styles/misc";

import { getAPIErrorMessage } from "../../helpers/action";
import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import {
  cloneCollections,
  getSelectOptions,
  doArraySearch,
  doPagination,
  getSelectedValue,
} from "../../helpers/data";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { programSchema } from "../../schemas/program";
import { courseSchema } from "../../schemas/course";

import { fb_checkLMSCourseUsage } from "../../actions/programs/fb";
import { toggleLoader } from "../../actions/global";

const useStyles = (theme) => ({
  headcell: {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.background,
  },
  bodycell: {
    fontSize: "14px",
    verticalAlign: "top",
  },
});

class ProductsTable extends React.Component {
  state = {
    filterBy: "all",
    filterByType: "all",
    searchterms: "",
    sortBy: "name-asc",
    perPage: 20,
    page: 1,
    randNum: false,
  };

  handleProductRemove = (product_id) => (event) => {
    const { currentProgram, onUpdate, value } = this.props;
    let selected = this.getSelectedProduct(product_id);
    if (
      selected &&
      selected.lms_course_id &&
      currentProgram &&
      currentProgram.id
    ) {
      this.props.dispatch(toggleLoader(true));
      fb_checkLMSCourseUsage(selected.lms_course_id, currentProgram.id)
        .then((enrollments) => {
          this.props.dispatch(toggleLoader(false));
          if (enrollments && isArrayExists(enrollments)) {
            triggerErrorAlert("Active Student(s) found for this product.");
          } else {
            let newValue =
              value && isArrayExists(value) ? cloneCollections(value) : [];
            if (_find(newValue, { id: product_id })) {
              let pulled = _remove(newValue, { id: product_id });
            }
            // do update
            if (onUpdate) onUpdate(newValue, "products");
          }
        })
        .catch((error) => {
          this.props.dispatch(toggleLoader(false));
          triggerErrorAlert(getAPIErrorMessage(error));
        });
    }
  };

  handleFormUpdate = (product_id) => (event) => {
    const { onUpdate, value } = this.props;
    let newValue = value && isArrayExists(value) ? cloneCollections(value) : [],
      selected = this.getSelectedProduct(product_id);

    if (event.target.checked) {
      if (
        !_find(newValue, { id: product_id }) &&
        selected &&
        selected.id &&
        !_isEmpty(selected.id)
      )
        newValue.push({
          id: selected.id,
          name: selected.name || "",
          course_code: selected.course_code || "",
          lms_course_id: selected.lms_course_id || "",
          contact_hour: selected.contact_hour || "",
          product_level: selected.product_level || "",
          product_credit: selected.product_credit || "",
        });
    } else {
      if (_find(newValue, { id: product_id })) {
        let pulled = _remove(newValue, { id: product_id });
      }
    }

    // do update
    if (onUpdate) onUpdate(newValue, "products");
  };

  getSelectedProduct = (product_id) => {
    const { products_options } = this.props;
    return products_options && isArrayExists(products_options)
      ? _find(products_options, { id: product_id })
      : false;
  };

  isProductSelected = (product) => {
    const { value } = this.props;
    let selectedProduct =
      value && isArrayExists(value) ? _find(value, { id: product.id }) : false;
    return selectedProduct &&
      selectedProduct.id &&
      !_isEmpty(selectedProduct.id)
      ? selectedProduct.id
      : false;
  };

  reorganizeData = () => {
    const { searchterms, sortBy, perPage, page, filterBy, filterByType } =
      this.state;
    const { products_options } = this.props;
    let list =
        products_options && isArrayExists(products_options)
          ? cloneCollections(products_options)
          : [],
      totalCount = _size(list);

    // do search
    if (searchterms && !_isEmpty(searchterms)) {
      list = doArraySearch(list, searchterms, [
        "name",
        "course_type_label",
        "course_duration_label",
      ]);
      totalCount = _size(list);
    } // end - searchterms

    // do level filter
    if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
      list = _filter(list, { product_level: filterBy });
      totalCount = _size(list);
    }

    // do contact_hour filter
    if (filterByType && !_isEmpty(filterByType) && filterByType !== "all") {
      list = _filter(list, { contact_hour: filterBy });
      totalCount = _size(list);
    } // end - filterByType

    // manually remove L230A, L320A, L220A
    list = _filter(list, (item) => {
      return (
        item.course_code.trim() !== "L230A" &&
        item.course_code.trim() !== "L320A" &&
        item.course_code.trim() !== "L220A"
      );
    });
    totalCount = _size(list);

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(list)) {
      switch (sortBy) {
        case "course_code-desc":
          list = _sortBy(list, ["course_code"]);
          list = _reverse(list);
          break;
        case "course_code-asc":
          list = _sortBy(list, ["course_code"]);
          break;
        case "contact_hour_value-desc":
          list = _sortBy(list, ["contact_hour_value"]);
          list = _reverse(list);
          break;
        case "contact_hour_value-asc":
          list = _sortBy(list, ["contact_hour_value"]);
          break;
        case "product_level_value-desc":
          list = _sortBy(list, ["product_level_value"]);
          list = _reverse(list);
          break;
        case "product_level_value-asc":
          list = _sortBy(list, ["product_level_value"]);
          break;
        case "product_credit_value-desc":
          list = _sortBy(list, ["product_credit_value"]);
          list = _reverse(list);
          break;
        case "product_credit_value-asc":
          list = _sortBy(list, ["product_credit_value"]);
          break;
        case "name-desc":
          list = _sortBy(list, ["name"]);
          list = _reverse(list);
          break;
        case "name-asc":
          list = _sortBy(list, ["name"]);
          break;
      }
    } // end - sortBy

    // do pagination
    list = doPagination(list, perPage, page);

    return { totalCount, list };
  };

  getTotalCalculation = (type) => {
    const {
      value,
      products_options,
      contact_hour,
      product_level,
      product_credit,
    } = this.props;
    let total = 0;
    if (value && isArrayExists(value)) {
      _forEach(value, (item) => {
        let product = this.getSelectedProduct(item.id);
        if (product) {
          switch (type) {
            case "contact_hour":
              total += getSelectedValue(
                contact_hour,
                product.contact_hour,
                "id",
                "value",
                0
              );
              break;
            case "credit":
              total += getSelectedValue(
                product_credit,
                product.product_credit,
                "id",
                "value",
                0
              );
              break;
          }
        }
      });
    } // end - value
    return total;
  };

  renderCalculation = () => {
    return (
      <div style={{ paddingBottom: "15px" }}>
        <Typography variant="h6" style={{ paddingBottom: "5px" }}>
          Total Contact Hour: {this.getTotalCalculation("contact_hour")}
        </Typography>
        <Typography variant="h6">
          Total Credit: {this.getTotalCalculation("credit")}
        </Typography>
      </div>
    );
  };

  renderTableActions = () => {
    const {
      authData,
      course_type,
      course_duration,
      contact_hour,
      product_level,
      product_credit,
      viewMode,
    } = this.props;
    const { filterBy, filterByType, sortBy, perPage, searchterms } = this.state;
    return (
      <TableBar
        show={viewMode ? ["entries", "sort"] : null}
        filterBy={filterBy}
        sortBy={sortBy}
        perPage={perPage}
        searchterms={searchterms}
        sortByOptions={[
          { value: "name-asc", label: "Product Name ( A - Z)" },
          { value: "name-desc", label: "Product Name ( Z - A )" },
          { value: "course_code-asc", label: "Product Code ( A - Z)" },
          { value: "course_code-desc", label: "Product Code ( Z - A )" },
          {
            value: "contact_hour_value-asc",
            label: "Contact Hour ( Low - High )",
          },
          {
            value: "contact_hour_value-desc",
            label: "Contact Hour ( High - Low )",
          },
          { value: "product_level_value-asc", label: "Level ( Low - High )" },
          { value: "product_level_value-desc", label: "Level ( High - Low )" },
          { value: "product_credit_value-asc", label: "Credit ( Low - High )" },
          {
            value: "product_credit_value-desc",
            label: "Credit ( High - Low )",
          },
        ]}
        rightButtons={
          viewMode
            ? null
            : [
                <div
                  key="filterbytype"
                  style={{ maxWidth: "200px", width: "100%" }}
                >
                  <FormSelect2
                    label="Filter By"
                    placeholder={""}
                    options={getSelectOptions({
                      list: contact_hour || false,
                      options: [{ value: "all", label: "All Contact Hour" }],
                      keys: { value: "id", label: "label" },
                      sortBy: "label",
                    })}
                    value={filterByType || false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(newFilterBy) =>
                      this.setState({ filterByType: newFilterBy, page: 1 })
                    }
                  />
                </div>,
              ]
        }
        filterByWidth="200px"
        filterByOptions={getSelectOptions({
          list: product_level || false,
          options: [{ value: "all", label: "All Level" }],
          keys: { value: "id", label: "label" },
          sortBy: "label",
        })}
        onFilterByChange={(newFilterBy) =>
          this.setState({ filterBy: newFilterBy, page: 1 })
        }
        onEntriesChange={(newPerPage) =>
          this.setState({ perPage: newPerPage, page: 1 })
        }
        onSearchChange={(terms) =>
          this.setState({ searchterms: terms, page: 1 })
        }
        onSortByChange={(newSortBy) =>
          this.setState({ sortBy: newSortBy, page: 1 })
        }
        style={{ marginBottom: "20px" }}
      />
    );
  };

  renderPagination = (totalCount) => {
    const { perPage, page } = this.state;
    return (
      <Pagination
        total={totalCount}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        onPageChange={(newPage) => this.setState({ page: newPage })}
      />
    );
  };

  renderBody = (items) => {
    const {
      classes,
      authData,
      currentProgram,
      contact_hour,
      product_level,
      product_credit,
      viewMode,
    } = this.props;
    return (
      <TableBody>
        {items && isArrayExists(items) ? (
          items.map((item) => {
            return (
              <TableRow key={item.id} id={item.id}>
                <TableCell className={classes.bodycell}>
                  {viewMode ? (
                    <Checkbox
                      checked={this.isProductSelected(item) ? true : false}
                      onChange={() => {
                        /* do nothing */
                      }}
                      value={this.isProductSelected(item)}
                      color="primary"
                      style={{ padding: "0px" }}
                    />
                  ) : (
                    <>
                      <Checkbox
                        checked={this.isProductSelected(item) ? true : false}
                        onChange={this.handleFormUpdate(item.id)}
                        value={this.isProductSelected(item)}
                        color="primary"
                        disabled={
                          currentProgram &&
                          currentProgram.products &&
                          isArrayExists(currentProgram.products) &&
                          _find(currentProgram.products, { id: item.id }) &&
                          this.isProductSelected(item)
                            ? true
                            : false
                        }
                        style={{ padding: "0px" }}
                      />
                      {currentProgram &&
                      currentProgram.products &&
                      isArrayExists(currentProgram.products) &&
                      _find(currentProgram.products, { id: item.id }) &&
                      this.isProductSelected(item) ? (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "12px" }}>
                              Remove this product?
                            </span>
                          }
                        >
                          <ErrorButton
                            style={{ marginLeft: "8px", padding: "2px 4px" }}
                            minWidth="0px"
                            size="small"
                            noIconMargin="yes"
                            onClick={this.handleProductRemove(item.id)}
                          >
                            <i className="fa fa-remove"></i>
                          </ErrorButton>
                        </Tooltip>
                      ) : null}
                    </>
                  )}
                </TableCell>
                <TableCell className={classes.bodycell}>
                  <WrapWord>{item.course_code || ""}</WrapWord>
                </TableCell>
                <TableCell className={classes.bodycell}>
                  <WrapWord>{item.name || ""}</WrapWord>
                </TableCell>
                <TableCell className={classes.bodycell}>
                  <div style={{ textAlign: "center" }}>
                    {getSelectedValue(
                      contact_hour,
                      item.contact_hour,
                      "id",
                      "label"
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.bodycell}>
                  <div style={{ textAlign: "center" }}>
                    {getSelectedValue(
                      product_level,
                      item.product_level,
                      "id",
                      "label"
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.bodycell}>
                  <div style={{ textAlign: "center" }}>
                    {getSelectedValue(
                      product_credit,
                      item.product_credit,
                      "id",
                      "label"
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell className={classes.bodycell} colSpan={6}>
              No Product(s) found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.headcell} style={{ width: "10%" }}>
            Select
          </TableCell>
          <TableCell className={classes.headcell} style={{ width: "15%" }}>
            Product Code
          </TableCell>
          <TableCell className={classes.headcell} style={{ width: "30%" }}>
            Product Name
          </TableCell>
          <TableCell
            className={classes.headcell}
            style={{ width: "15%", textAlign: "center" }}
          >
            Contact Hour
          </TableCell>
          <TableCell
            className={classes.headcell}
            style={{ width: "15%", textAlign: "center" }}
          >
            Level
          </TableCell>
          <TableCell
            className={classes.headcell}
            style={{ width: "15%", textAlign: "center" }}
          >
            Credit
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  render = () => {
    const { classes } = this.props;
    const { list, totalCount } = this.reorganizeData();
    return (
      <>
        {this.renderCalculation()}
        {this.renderTableActions()}
        <Table>
          {this.renderHeader()}
          {this.renderBody(list)}
        </Table>
        {this.renderPagination(totalCount)}
      </>
    );
  };
}

export default compose(connect(), withStyles(useStyles))(ProductsTable);
